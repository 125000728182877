<template>
  <div
    v-infinite-scroll="loadMoreUsers"
    infinite-scroll-disabled="infinityScrollBusy"
  >
    <v-card>
      <v-card-title>
        {{ $t('ListUsers') }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="searchUsersForm"
          @submit.prevent="handleGetListUsers"
        >
          <v-text-field
            v-model="keyword"
            :label="$t('Search')"
            outlined
            dense
            :placeholder="$t('SearchByPhoneOrName')"
            hide-details="auto"
            @keydown.enter.prevent="handleKeywordChange"
          >
            <template slot="append">
              <v-progress-circular
                v-if="loading"
                width="2"
                size="20"
                indeterminate
              ></v-progress-circular>
              <v-icon
                v-else
                @click="handleKeywordChange"
              >
                {{ mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-list>
        <template
          v-for="(item, index) in users"
        >
          <v-list-item
            :key="item.id"
          >
            <v-list-item-avatar>
              <v-img
                v-if="!!item.face_image_url"
                :src="item.face_image_url"
              ></v-img>
              <v-img
                v-else
                :src="require(`@/assets/images/avatars/${index % 9 + 1}.png`)"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-weight-semibold primary--text">
                {{ item.full_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text--primary">
                  {{ item.phone }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div class="text-center">
                <v-icon v-if="!!item.is_lock">
                  {{ mdiLockOutline }}
                </v-icon>
                <router-link :to="{name: 'staff-user-update', params: {id: item.id_user}}">
                  <v-btn icon>
                    <v-icon>
                      {{ mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </router-link>
                <router-link :to="{name: 'staff-user-detail', params: {id: item.id_user}}">
                  <v-btn icon>
                    <v-icon>
                      {{ mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                </router-link>
              </div>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < users.length - 1"
            :key="`divider-${item.id_user}`"
            :inset="item.inset"
          ></v-divider>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiPencilOutline, mdiLockOutline, mdiMagnify, mdiLoading } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AccountService from '@/services/AccountService'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  setup() {
    const searchUsersForm = ref(null)
    const keyword = ref('')
    const users = ref([])
    const loading = ref(false)
    const infinityScrollBusy = ref(false)
    const stopLoadMoreUsers = ref(false)
    const page = ref(1)
    const limit = 10

    const handleGetListUsers = async () => {
      loading.value = true
      try {
        const resp = await AccountService.getListUsers({
          page: page.value,
          limit,
          order_by: 'created_at',
          order_sort: 'DESC',
          keyword: keyword.value,
        })
        const { data } = resp.data
        if (data.length === 0) {
          stopLoadMoreUsers.value = true
        } else {
          users.value = [...users.value, ...data]
          page.value += 1
          stopLoadMoreUsers.value = false
        }
      } catch {
      } finally {
        loading.value = false
      }
    }

    const loadMoreUsers = async () => {
      if (!stopLoadMoreUsers.value) {
        infinityScrollBusy.value = true
        await handleGetListUsers()
        infinityScrollBusy.value = false
      }
    }

    const handleKeywordChange = () => {
      page.value = 1
      users.value = []
      handleGetListUsers()
    }

    return {
      loading,
      keyword,
      users,
      mdiEyeOutline,
      mdiPencilOutline,
      mdiLockOutline,
      mdiMagnify,
      mdiLoading,
      loadMoreUsers,
      infinityScrollBusy,
      handleGetListUsers,
      handleKeywordChange,

      // template ref
      searchUsersForm,
    }
  },
  directives: { infiniteScroll },
}
</script>
